@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import '~bootstrap/scss/bootstrap';
*,
::after,
::before {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    color: #000;

    &.dark {
        background-color: #e9e9e9;
    }
}

p {
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

canvas {
    outline: none;
    /* pointer-events: none; */
    transform: translateY(-15%);
}

.main-window {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px 0 0;
}

.canvas-wrapper {
    width: 60%;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;

    /* height: 120vh; */
}

.forms-wrapper {
    margin-top: 30px;
    width: 40%;
}

.button-wrapper .add-to-cart {
    display: block;
    margin: 20px 0;
    width: 80%;
    border: 2px solid #3842bb;
    padding: 10px;
    text-align: center;
    border-radius: 0px;
}

.custom-select-wrapper {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.custom-select-wrapper.input {
    flex-direction: column;
    align-items: initial;

}

.custom-select-wrapper.double {
    flex-wrap: wrap;
}

.custom-select-wrapper.double select {
    margin-top: 20px;
    width: 80%;
}

.custom-select-wrapper.double .get-answer-btn {
    margin-top: 20px;
}

.custom-select-wrapper .wrapper {
    display: flex;
    flex-direction: row;

}

.custom-select-wrapper .wrapper .text-alert {
    display: block;
    flex-basis: 100%;
    text-align: center;

}

.custom-select-wrapper .text {
    flex-basis: 30%;
    flex-shrink: 0;
}

.custom-select-wrapper select {
    width: 50%;
    padding: 5px;
    border: 2px solid #3842bb;
}

.custom-select-wrapper select:focus {
    border-color: none;
    outline: none;
}

.custom-select-wrapper .get-answer-btn {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border: 2px solid #3842bb;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    margin-left: 15px;
}

.custom-select {
    flex-grow: 1;
}

.text-alert {
    color: darkred;
    font-weight: bold;
}

.slider {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 13%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

}

.slider .slider-item {
    cursor: pointer;
    width: 25%;
    padding: 10px 0;
    border: 2px solid #3842bb;
    border-radius: 10px;
    background: #fff;
    text-align: center;
    user-select: none;
    margin: 8px 0;
}

.slider .slider-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.slider .controls-block {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    flex-basis: 100%;
}

.disabled {
    color: rgb(84, 84, 84);
    cursor: default;
    background-color: rgba(239, 239, 239, 0.3) !important;
    border-color: rgba(118, 118, 118, 0.3) !important;
    pointer-events: none;
}

@media (max-width: 768px) {
    .main-window {
        flex-direction: column;
        padding: 0 20px;
    }

    .canvas-wrapper {
        position: relative;
        width: 100%;
        min-height: 650px;
        overflow: hidden;
    }

    canvas {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -15%)
    }

    .title {
        font-size: 8vw;
    }

    .price {
        font-size: 4vw;
    }

    .forms-wrapper {
        width: 100%;
    }

    .custom-select-wrapper .text {
        font-size: 5vw;
        padding-right: 10px;
    }

    .custom-select {
        font-size: 4vw;
        padding: 5px;
    }

    .custom-select-wrapper .get-answer-btn {
        width: 7vw;
        height: 7vw;
        font-size: 4vw;
    }

    .slider {
        justify-content: space-between;
        bottom: 3%;
    }

    .slider-item {
        width: 47% !important;
    }

    .controls-block {
        justify-content: space-between !important;
    }

    .controls-block .slider-item {
        padding: 10px 20px;
    }

    .button-wrapper .add-to-cart {
        width: 100%;
        font-size: 4vw;
    }

    .text-alert {
        font-size: 4vw;
    }

    .cn {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.glass-content {
    position: absolute;
    right: 3rem;
    top: 30%;
}

.glass-content div {
    width: 3rem;
    height: 3rem;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.glass-content div img {
    width: 100%;
    height: 100%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 1) !important;
}
